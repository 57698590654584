var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyOrderJieSuan" },
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _vm.isWaitHandle
                    ? _c(
                        "div",
                        _vm._l(_vm.buyJieSuan, function(item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "cell" },
                            [
                              _c("div", { staticClass: "line" }),
                              _c("div", { staticClass: "orderDataInfo" }, [
                                _c("div", { staticClass: "flex-between" }, [
                                  _c("div", { staticClass: "flex" }, [
                                    _c("h3", [_vm._v("黄金金条9999")])
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        size: true,
                                        daifukuanBg:
                                          item.payStatus != "PAYED"
                                            ? true
                                            : false,
                                        yifukaunBg:
                                          item.payStatus == "PAYED"
                                            ? true
                                            : false
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.payStatus == "WAIT"
                                            ? "待付款"
                                            : item.payStatus == "PAYED"
                                            ? "已付款"
                                            : "待确认"
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "weight flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "size colore1 infoWidth" },
                                      [_vm._v("总克重（克）")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "size color7",
                                        staticStyle: { marginLeft: "6px" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.weight.toFixed(2))
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "price flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "size colore1 infoWidth" },
                                      [_vm._v("总金额（元/克）")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "size color7",
                                        staticStyle: { marginLeft: "6px" }
                                      },
                                      [_vm._v(" " + _vm._s(item.money))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "time flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "size colore1 infoWidth" },
                                      [_vm._v("下单时间")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "size color7",
                                        staticStyle: { marginLeft: "6px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatDate_")(
                                                item.created
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "flex-between" }, [
                                  _c(
                                    "div",
                                    { staticClass: "orderInfo colore1" },
                                    [
                                      _vm._v(
                                        "订单单号 : " + _vm._s(item.openNo)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "xiangqing",
                                      on: {
                                        click: function($event) {
                                          return _vm.buyOrderJieSuanDatails(
                                            item.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("详情 "),
                                      _c("div", { staticClass: "rightIcon" })
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(_vm.list, function(item) {
                          return _c("div", { key: item.id }, [
                            item.orderType == "BUY_VIOLATE"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "cell",
                                    on: {
                                      click: function($event) {
                                        return _vm.toViolate(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("结算")
                                      ])
                                    ]),
                                    _c("div", { staticClass: "right" }, [
                                      _c(
                                        "div",
                                        { staticClass: "violateOrderInfo" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "flex_between" },
                                            [
                                              _c(
                                                "h1",
                                                { staticClass: "comeWight" },
                                                [
                                                  _vm._v(
                                                    "提料重量 " +
                                                      _vm._s(item.weight) +
                                                      "g"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "status" },
                                                [_vm._v("已完成")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "BrokenWeight" },
                                            [
                                              _vm._v(
                                                "折足重量" +
                                                  _vm._s(item.weight) +
                                                  "g"
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "orderNmber" }, [
                                        _c(
                                          "div",
                                          { staticClass: "flex_between" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "number van-ellipsis"
                                              },
                                              [
                                                _vm._v(
                                                  "订单单号:" +
                                                    _vm._s(item.openNo) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "time" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate_")(
                                                    item.created
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              : _c("div", { staticClass: "cell" }, [
                                  _c("div", { staticClass: "line" }),
                                  _c("div", { staticClass: "orderDataInfo" }, [
                                    _c("div", { staticClass: "flex-between" }, [
                                      _c("div", { staticClass: "flex" }, [
                                        _c("h3", [_vm._v("黄金金条9999")])
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            size: true,
                                            daifukuanBg:
                                              item.payStatus != "PAYED"
                                                ? true
                                                : false,
                                            yifukaunBg:
                                              item.payStatus == "PAYED"
                                                ? true
                                                : false
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.payStatus == "WAIT"
                                                ? "待付款"
                                                : item.payStatus == "PAYED"
                                                ? "已付款"
                                                : "待确认"
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "info" }, [
                                      _c(
                                        "div",
                                        { staticClass: "weight flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "size colore1 infoWidth"
                                            },
                                            [_vm._v("总克重（克）")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "size color7",
                                              staticStyle: { marginLeft: "6px" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.weight.toFixed(2))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "price flex" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "size colore1 infoWidth"
                                          },
                                          [_vm._v("总金额（元/克）")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "size color7",
                                            staticStyle: { marginLeft: "6px" }
                                          },
                                          [_vm._v(" " + _vm._s(item.money))]
                                        )
                                      ]),
                                      _c("div", { staticClass: "time flex" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "size colore1 infoWidth"
                                          },
                                          [_vm._v("下单时间")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "size color7",
                                            staticStyle: { marginLeft: "6px" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatDate_")(
                                                    item.created
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "flex-between" }, [
                                      _c(
                                        "div",
                                        { staticClass: "orderInfo colore1" },
                                        [
                                          _vm._v(
                                            "订单单号 : " + _vm._s(item.openNo)
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "xiangqing",
                                          on: {
                                            click: function($event) {
                                              return _vm.buyOrderJieSuanDatails(
                                                item.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("详情 "),
                                          _c("div", {
                                            staticClass: "rightIcon"
                                          })
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                          ])
                        }),
                        0
                      )
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }