var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyOrder" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "", sticky: "", type: "card" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "买料定价", name: "buyPrice" } }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _c(
                  "van-tabs",
                  {
                    attrs: { animated: "", sticky: "", swipeable: "" },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.name, function(item, index) {
                    return _c(
                      "van-tab",
                      {
                        key: index,
                        attrs: {
                          title:
                            item == "ALL"
                              ? "全部"
                              : item ==
                                "SUSPENSE,WAIT_FETCH,WAIT_PAYED,WAIT_FETCH_FINISHED"
                              ? "进行中"
                              : "已完成",
                          name: item
                        }
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _c("buyOrderPrice", {
                              ref: "buyOrder",
                              refInFor: true,
                              attrs: { activeName: _vm.activeName },
                              on: { getData: _vm.getData }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "买料结算", name: "buyJieSuan" } },
            [
              _c("buyOrderJieSuan", {
                attrs: { isWaitHandle: _vm.isWaitHandle }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabsLeftIcon" },
        [
          _c("van-icon", {
            attrs: { name: "arrow-left" },
            on: { click: _vm.leftIcon }
          })
        ],
        1
      ),
      _vm.active == "buyPrice"
        ? _c("footer", [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "leftText" }, [
                _vm._v("重量 : " + _vm._s(_vm.weight ? _vm.weight : 0) + "g")
              ])
            ]),
            _c(
              "div",
              { staticClass: "right", on: { click: _vm.takeMaterial } },
              [_vm._v("取料")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }